import { Component, Input, OnInit } from "@angular/core";
import { Config } from "src/app/config";

@Component({
	selector: "callus",
	templateUrl: "./callus.navbar.html",
	styleUrls: ["./callus.navbar.scss"],
})
export class CallusNavbar implements OnInit {
	@Input() internalPage = false;

	@Input() border = false;

	public phone: string = `${Config.contact.prefix} ${Config.contact.phone}`;

	public phoneLink: string;

	constructor() {}

	ngOnInit() {
		this.phoneLink = `tel:${this.getPhoneLink()}`;
	}

	/** Parsea el numero de telefono */
	public getPhoneLink() {
		return this.phone.replace(/-/g, "").replace(/ /g, "");
	}
}
