import { AfterViewInit, Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Config } from "src/app/config";
import { FLAG, FOOTER_LINK_ABOUT, FOOTER_LINK_ACCESS } from "src/app/constants";
import { whatsappEndpoint } from "src/app/functions/whatsapp";
import { ApiCountriesService } from "src/app/services/api/api-countries.service";
import { PlatformService } from "src/app/services/platform.service";
import { RouterPathsService } from "src/app/services/router-paths.service";
import { SessionService } from "src/app/services/session.service";

declare const Pulsetic: any;

@Component({
	selector: "cp-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit, AfterViewInit {
	/** Nombre del Sitio */
	public siteName: string;
	/** Links `Accesos` */
	public linksAccess = FOOTER_LINK_ACCESS;
	/** Links `Acerca de la empresa` */
	public linksAbout = FOOTER_LINK_ABOUT;
	/** Telefonos rotativos */
	public rotativeLines: string;
	/** Whatsapp  */
	public whatsapp: string;
	/** Crowdium en otros paises */
	public countries = Config.countries;
	/** Link de Whatsapp */
	public whatsappLink: string;
	/** Link de Telefono Rotativo */
	public phoneLink: string;
	/** Año actual */
	public year = new Date().getFullYear();

	constructor(public Paths: RouterPathsService, private Platform: PlatformService, private Session: SessionService, private APICountries: ApiCountriesService, private Translate: TranslateService) {
		const { name } = this.Session.site;
		this.siteName = name;
		const { phone, whatsapp, prefix } = Config.contact;
		this.rotativeLines = `${prefix} ${phone}`;
		this.whatsapp = `${prefix} ${whatsapp}`;
		this.whatsappLink = whatsappEndpoint(this.getNumberLink(this.whatsapp), this.Translate.instant("whatsapp.message"));
		this.phoneLink = `tel:${this.getNumberLink(this.rotativeLines)}`;
	}

	ngOnInit() {}

	ngAfterViewInit(): void {
		if (!this.Platform.isBrowser) return;
		Pulsetic.init({
			monitor_id: 7715,
			element_id: "pulsetic-badge",
		});
	}

	/** Obtiene la imagen de pais */
	public getFlag(country: string) {
		if (this.APICountries.countries) {
			const c = this.APICountries.countries.find((c) => c.alpha2Code.toLowerCase() === country.toLowerCase());
			if (c) return FLAG(c.alpha2Code);
		}
		return "";
	}
	/** parsea el numero de telefono */
	private getNumberLink(num) {
		return num.replace(/-/g, "").replace(/ /g, "");
	}
}
